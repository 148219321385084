import { TextField, Box } from '@mui/material';
import { LocalizationProvider, DatePicker as MuiDatePicker, StaticDatePicker as MuiStaticDatePicker, PickersDay, CalendarPickerSkeleton } from '@mui/lab';
import { isMobile } from 'react-device-detect';
import { useSelector } from "react-redux";
import { getData } from "../api/dataSlice";
import { useTranslation } from 'react-i18next';
import DayjsAdapter from '@mui/lab/AdapterDayjs';
import i18next from 'i18next';
import dayjs from 'dayjs';

export default function DatePicker(props) {

    const { timestamp, setTimestamp, loading, shouldDisableDate, siderWidth, pt = 0, pb = 0 } = props
    const { source } = useSelector(getData)
    const { t } = useTranslation();

    const daySize = 34
    const minDate = dayjs(source?.[0]?.[0]?.[0])
    const maxDate = dayjs(source?.[0]?.[source[0].length-1]?.[0])

    return (
        <LocalizationProvider 
            dateAdapter={DayjsAdapter}
            locale={i18next.language}
        >
            {isMobile
            ? <Box sx={{ pb, pt, width: "100%" }}>
                <MuiDatePicker
                    label={t("date.date")}
                    inputFormat={(t("date.format.full"))}
                    mask={t("date.mask.full")}
                    renderInput={props => <TextField {...props} fullWidth />}
                    value={timestamp}
                    onChange={setTimestamp}
                    cancelText={t("actions.cancel")}
                    minDate={minDate}
                    maxDate={maxDate}
                    loading={loading}
                    shouldDisableDate={shouldDisableDate}
                    renderLoading={() => <CalendarPickerSkeleton />}
                    showTodayButton
                    todayText={t("date.today")}
                    showToolbar={false}
                />
            </Box>
            : <Box
                sx={{
                    "& > div": {
                        minWidth: siderWidth,
                        pb,
                        pt
                    },
                    "& > div > div": {
                        border: "1px solid",
                        borderColor: "#BDBDBD",
                        borderRadius: "4px"
                    },
                    "& > div > div, & > div > div > div, & > div > div > div > div": {
                        width: siderWidth - 2,
                        // minHeight: siderWidth + 24,
                    },
                    "& .MuiTypography-caption": {
                        width: daySize + 4,
                        margin: 0
                    },
                    "& .PrivatePickersSlideTransition-root": {
                        minHeight: daySize * 6.3,
                        overflow: "hidden"
                    },
                    '& .PrivatePickersSlideTransition-root [role="row"]': {
                        margin: 0
                    },
                    // "& .MuiPickersDay-dayWithMargin": {
                    //     margin: 0
                    // },
                    "& .MuiPickersDay-root": {
                        width: daySize,
                        height: daySize
                    },
                    "& .MuiYearPicker-root": {
                        justifyContent: "center"
                    },
                    // "& .MuiCalendarPicker-viewTransitionContainer": {
                    //     overflow: "hidden"
                    // }
                }}
            >
                <MuiStaticDatePicker
                    displayStaticWrapperAs="desktop"
                    openTo="day"
                    renderInput={() => {}}
                    value={timestamp}
                    onChange={setTimestamp}
                    renderDay={(day, _value, DayComponentProps) => {
                        // https://mui.com/x/react-date-pickers/date-picker/#dynamic-data=
                        return (
                            <PickersDay 
                                {...DayComponentProps} 
                            />
                        );
                    }}
                    minDate={minDate}
                    maxDate={maxDate}
                    loading={loading}
                    shouldDisableDate={shouldDisableDate}
                    renderLoading={() => <CalendarPickerSkeleton />}
                />
            </Box>}
        </LocalizationProvider>
    )
}