import React, { createContext, useContext, useEffect, useState } from 'react';
import { Box, CircularProgress } from "@mui/material";
import { setToken } from "../api/dataSlice";
import { useDispatch } from 'react-redux';
import Keycloak from 'keycloak-js';

const KeycloakContext = createContext()
const { DEBUG, KEYCLOAK: { REALM, SERVER, CLIENT_ID } } = window.conf

export const KeycloakProvider = ({ children }) => {

    const dispatch = useDispatch()

    const [keycloak, setKeycloak] = useState(null)
    const [initialized, setInitialized] = useState(false)
    
    const initKeycloak = () => {
        
        const keycloak = new Keycloak({
            realm: REALM,
            url: SERVER,
            clientId: CLIENT_ID
        })

        keycloak.onTokenExpired = () => {
            keycloak.updateToken(5)
            .then(() => {
                dispatch(setToken(keycloak.token))
                DEBUG && console.log("[KEYCLOAK] New token", keycloak.token, keycloak.tokenParsed)
            })
            .catch(keycloak.logout) // TODO: Not required if an error message could be displayed
        }

        keycloak.init({
            onLoad: 'check-sso', // 'login-required' | 'check-sso'
            silentCheckSsoRedirectUri: `${window.location.origin}/silent-check-sso.html`,
            pkceMethod: 'S256',
            enableLogging: DEBUG
        })
        .then(authenticated => {
            if (authenticated && keycloak.token) {
                dispatch(setToken(keycloak.token))
                DEBUG && console.info(`[KEYCLOAK] User is authenticated`, keycloak.token, keycloak.tokenParsed)
            } else {
                DEBUG && console.warn(`[KEYCLOAK] User is not authenticated`)
            }
            setInitialized(true)
            DEBUG && console.info(`[KEYCLOAK] Successfully initialized`)
        })
        .catch(error => console.error('[KEYCLOAK] Failed to initialize:', error))

        setKeycloak(keycloak)
    }

    useEffect(() => initKeycloak(), []) // eslint-disable-line react-hooks/exhaustive-deps

    if (!initialized) {
        return (
            <Box sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
                height: '100vh'
            }}>
                <CircularProgress size={60} />
            </Box>
        )
    }

    return (
        <KeycloakContext.Provider value={keycloak}>
            {children}
        </KeycloakContext.Provider>
    )
}

export const useKeycloak = () => {

    const keycloak = useContext(KeycloakContext)

    if (!keycloak) {
        throw new Error('[KEYCLOAK] useKeycloak must be used within a KeycloakProvider')
    }

    return {
        doLogin: keycloak?.login,
        doLogout: keycloak?.logout,
        getToken: () => keycloak?.token,
        isLoggedIn: () => !!keycloak?.token,
        hasRole: (role) => keycloak?.hasResourceRole(role),
        hasResource: (resource) => !!keycloak?.tokenParsed?.resource_access?.[resource],
        isUsersPortal: () => !!keycloak?.tokenParsed?.resource_access?.[CLIENT_ID],
        getUsername: () => keycloak?.tokenParsed?.preferred_username,
        getEmail: () => keycloak?.tokenParsed?.email
    }
}