import React, { useState, useMemo, useEffect } from 'react';
import { Dialog, MenuItem, DialogContent, Fab, Tooltip, Button, Badge, Grid, FormControl, InputLabel, Select, IconButton, Popover, Typography, Alert, Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { Info, LibraryBooks } from '@mui/icons-material';
import { pdfjs, Document, Page } from 'react-pdf';
import { checkFile, saveFile, useWindowDimensions } from '../Utilities';
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import { isMobile, useMobileOrientation } from 'react-device-detect';
import dayjs from 'dayjs';
import DatePicker from '../DatePicker';
import 'react-pdf/dist/Page/AnnotationLayer.css';
import 'react-pdf/dist/Page/TextLayer.css';

pdfjs.GlobalWorkerOptions.workerSrc = new URL('pdfjs-dist/build/pdf.worker.min.js', import.meta.url).toString();

export default function Diary({ timestamp, setTimestamp, loading, disabled, shouldDisableDate, siderWidth }) {
    
    const { CAMERAS, LAYOUT: { DIARY: { AI }, TITLE }, BASEURL } = window.conf;
    const { t } = useTranslation();
    const { contentHeight, windowWidth } = useWindowDimensions()
    const { isPortrait } = useMobileOrientation();

    const [featureDetailId, setFeatureDetailId] = useState(CAMERAS?.length ? CAMERAS[0].featureDetailId : 0);
    const [open, setOpen] = useState(false);
    const [anchorPopover, setAnchorPopover] = useState(null);
    const [numPages, setNumPages] = useState(null);
    const [pdfExists, setPdfExists] = useState(false);
    const [alertMessage, setAlertMessage] = useState({ type: 'success', message: null });

    const openPopover = Boolean(anchorPopover)
    const idPopover = openPopover ? 'simple-popover' : undefined
    const moreThanOneCam = CAMERAS.length > 1
    const selectedCam = CAMERAS.find(camera => camera.featureDetailId === featureDetailId)
    const selectedDate = timestamp.format("YY-MM-DD")
    const hasAlert = alertMessage.message
    const maxWindowWidth = 900
    const contentOffset = { 
        height: 3 * 16 + 56 + (moreThanOneCam && 72), // 3 * 16px padding + 56px button height and tolerance + 72px camera selector
        width: 2 * 16 + (windowWidth > maxWindowWidth ? 24 : 84) // 2 * 16px padding + 24px or 84px tolerance
    }
    const staticReport = false
    const reportPath = staticReport ? `https://pdfobject.com/pdf/sample.pdf` : `${BASEURL}cam${selectedCam.id + 1}/reports/${selectedDate}.pdf`
    const pdfSource = useMemo(() => ({ url: reportPath }), [reportPath]);
    const today = dayjs()
    const title = TITLE ? `${TITLE}_` : ""
    const camera = selectedCam.name 
        ? `${selectedCam.name}_` 
        : moreThanOneCam 
            ? `${t("events.camera")} ${selectedCam.id + 1}_` 
            : ""
    const targetFilename = `${title}${camera}${selectedDate}` || t("events.construction_diary.construction_diary")

    useEffect(() => {
        const checkPdf = async() => {
            const { exists, contentType } = await checkFile(pdfSource.url)
            if (exists && contentType === "pdf") {
                setAlertMessage({ type: 'success', message: '' })
                setPdfExists(true)
            } else {
                if (today.isSame(timestamp, 'day')) {
                    setAlertMessage({ type: 'warning', message: t('events.construction_diary.no_data_today') })
                } else {
                    setAlertMessage({ type: 'error', message: t('msg.no_data') })
                }
                setPdfExists(false)
            }
        }
        checkPdf()
    }, [pdfSource.url, timestamp]) // eslint-disable-line react-hooks/exhaustive-deps

    const onOpen = () => {
        setFeatureDetailId(CAMERAS?.length ? CAMERAS[0].featureDetailId : 0)
        setOpen(true)
    }

    const onClose = () => {
        setOpen(false)
    }

    const onOpenPopover = (event) => {
        setAnchorPopover(event.currentTarget)
    }
    
    const onClosePopover = () => {
        setAnchorPopover(null)
    }

    const handleCameraChange = (event) => {
        setFeatureDetailId(event.target.value)
    }

    const handleDownload = () => {


        fetch(pdfSource.url)
        .then(res => res.ok ? res.blob() : console.error(res.status, res.statusText))  
        .then(blob => saveFile(blob, `${targetFilename}.pdf`))
        .catch(error => console.error(error))
    }

    const StyledBadge = styled(Badge)(({ theme, loading }) => ({
        '& .MuiBadge-badge': {
            right: -18,
            top: 30,
            border: `1px solid ${theme.palette.background.paper}`,
            backgroundColor: loading ? theme.palette.action.disabledBackground : "#FF6600"
        }
    }))

    const PanningInteractionHandler = (instance, state) => {
        if (state?.positionX >= 0 && state?.positionY >= 0 && state?.scale <= 1) {
          instance.setup.panning.disabled = true;
        } else {
          instance.setup.panning.disabled = false;
        }
    }

    const pdfDocument = (
        <Document 
            file={pdfSource}
            loading={t('actions.loading')}
            onLoadSuccess={({ numPages }) => setNumPages(numPages)}
            error={t('msg.exe_error')}
            onLoadError={error => console.error(`[Diary] PDF load error`, error)}
            noData={t('msg.no_data')}
        >

            {[...Array(numPages)].map((_, index) => (
                <Page
                    key={`page_${index + 1}`}
                    pageNumber={index + 1}
                    width={(windowWidth > maxWindowWidth ? maxWindowWidth : windowWidth) - contentOffset.width}
                />
            ))}

        </Document>
    )

    const cameraSelection = moreThanOneCam && (
        <FormControl 
            fullWidth 
            sx={{ pb: 2 }}
        >
            <InputLabel id='selectCameraLabel'>
                {t("events.camera")}
            </InputLabel>
            <Select
                labelid='selectCameraLabel'
                label={t("events.camera")}
                value={featureDetailId}
                onChange={handleCameraChange}
            >
                {CAMERAS.map(camera => (
                    <MenuItem
                        key={camera.id}
                        value={camera.featureDetailId}
                    >
                        {camera.name !== "" ? camera.name : `${t("events.camera")} ${camera.id + 1}`}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    )

    return (
        <>
            {isMobile && isPortrait ? (
                <Tooltip title={t('events.construction_diary.create_diary')} placement="top" arrow>
                    <span>
                        <Fab
                            area-label="Construction Diary"
                            size="medium"
                            color="primary"
                            onClick={onOpen}
                            disabled={loading || disabled}
                            sx={{ mr: '2px', ml: '2px' }}
                            variant='extended'
                        >
                            {disabled ? (
                                <>
                                    <LibraryBooks sx={{ mr: 1 }} />
                                    {t('events.construction_diary.construction_diary_short')}
                                </>
                            ) : (
                                <StyledBadge
                                    badgeContent="BETA" 
                                    loading={loading ? 1 : 0}
                                >
                                    <LibraryBooks sx={{ mr: 1 }} />
                                    {t('events.construction_diary.construction_diary_short')}
                                </StyledBadge>
                            )}
                        </Fab>
                    </span>
                </Tooltip>
            ) : (
                <Box sx={{ width: "100%", display: "flex", flexDirection: "column" }}>
                    {cameraSelection}

                    <DatePicker
                        timestamp={timestamp}
                        setTimestamp={setTimestamp}
                        loading={loading}
                        shouldDisableDate={shouldDisableDate}
                        siderWidth={siderWidth}
                        pb={2}
                    />

                    <Button
                        onClick={onOpen}
                        startIcon={<LibraryBooks />}
                        disabled={loading || disabled}
                        variant="contained"
                        size='large'
                        fullWidth
                    >
                        {t("actions.create")}
                    </Button>
                </Box>
            )}

            <Dialog 
                open={open} 
                onClose={onClose}
                sx={{ "& .MuiDialog-paper": { maxWidth: windowWidth > maxWindowWidth ? maxWindowWidth : windowWidth }}}
            >

                <DialogContent sx={{ p: 2 }}>

                    <Grid container>

                        <Grid item xs={12} sx={{ pb: 0 }} /> {/* An empty grid item. A trick to fix the lable layout issue on FormControl */}

                        {isMobile && isPortrait && cameraSelection}

                        {pdfExists && (
                            <Grid 
                                item 
                                xs={12} 
                                sx={{ 
                                    mb: 2,
                                    border: '1px solid',
                                    borderColor: 'rgba(196,196,196)',
                                    borderRadius: '4px',
                                    height: contentHeight - contentOffset.height,
                                    overflow: 'auto'
                                }}
                            >

                                {isMobile ? (
                                    <TransformWrapper
                                        onZoomStop={ref => PanningInteractionHandler(ref.instance, ref.state)}
                                        onPanning={ref => PanningInteractionHandler(ref.instance, ref.state)}
                                        onInit={ref => ref.instance.setup.panning.disabled = true}
                                    >
                                        <TransformComponent>
                                            {pdfDocument}
                                        </TransformComponent>
                                    </TransformWrapper> 
                                ) : pdfDocument}

                            </Grid>
                        )}

                        {hasAlert && (
                             <Grid item xs={12} sx={{ pb: 2 }}>
                                <Alert
                                    variant="filled"
                                    severity={alertMessage?.type}
                                    sx={{ alignItems: 'center' }}
                                >
                                    {alertMessage?.message}
                                </Alert>
                            </Grid>
                        )}

                        <Grid
                            item 
                            xs={12}
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-between'
                            }}
                        >
                            <Button onClick={onClose} variant="outlined">
                                {t('actions.close')}
                            </Button>

                            {AI && (
                                <IconButton
                                    aria-label={idPopover}
                                    onClick={onOpenPopover}
                                    color="primary"
                                >
                                    <Info />
                                </IconButton>
                            )}
                             
                            <Button 
                                variant="outlined" 
                                onClick={handleDownload}
                                disabled={!pdfExists || !numPages}
                            >
                                {t('actions.download')}
                            </Button>

                        </Grid>

                    </Grid>
                </DialogContent>
            </Dialog>

            <Popover
                id={idPopover}
                open={openPopover}
                anchorEl={anchorPopover}
                onClose={onClosePopover}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center'
                }}
                transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center'
                }}
            >
                <Typography sx={{ 
                    p: 2, 
                    whiteSpace: "pre-line", 
                    userSelect: "none" 
                }}>
                    {t('events.construction_diary.info')}
                </Typography>
            </Popover>
        </>
    )
}