import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween'
import DayjsAdapter from '@mui/lab/AdapterDayjs';
import i18next from 'i18next';
import React, { useEffect, useState } from 'react';
import { MenuItem, Box, TextField, ToggleButton, ToggleButtonGroup, Alert } from '@mui/material';
import { StaticDateRangePicker, LocalizationProvider, LoadingButton, CalendarPickerSkeleton, DatePicker } from '@mui/lab';
import { Download as DownloadIcon } from '@mui/icons-material';
import { isMobile, useMobileOrientation } from 'react-device-detect';
import { Zip, ZipPassThrough } from 'fflate';
import { getData, setArchiveProgress } from "../../api/dataSlice"
import { useDispatch, useSelector } from "react-redux"
import { databaseDateToFilename, saveFile } from '../Utilities';
import { useTranslation } from 'react-i18next';
import { Progress } from '../Progress';

dayjs.extend(isBetween)

export default function Download(props) {

    const { t } = useTranslation()
    const { setError, timestamp, siderWidth, shouldDisableDate, loading } = props
    const { isPortrait } = useMobileOrientation()
    const { source, archiveProgress } = useSelector(getData)
    const { CAMERAS, DEBUG, LAYOUT: { TITLE } } = window.conf

    const [key, setKey] = useState(true)
    const [selectedCamera, setSelectedCamera] = useState(CAMERAS?.[0]?.id)
    const [downloadSpan, setDownloadSpan] = useState([timestamp, timestamp])
    const [predefinedSpan, setPredefinedSpan] = useState("")
    const [isLoadingDownload, setLoadingDownload] = useState(false)
    const [alertMessage, setAlertMessage] = useState(null);
    const [startDateValid, setStartDateValid] = useState(true)
    const [endDateValid, setEndDateValid] = useState(true)

    const dispatch = useDispatch()
    const daySize = 34
    const today = dayjs()
    const isDownloadRangeExceeded = Math.abs(downloadSpan?.[0]?.diff(downloadSpan?.[1], 'day')) > 31
    const isDisabledDownload = !source?.[0]?.length || selectedCamera == null || isDownloadRangeExceeded || !startDateValid || !endDateValid || isLoadingDownload || archiveProgress > 0
    const minDate = dayjs(source?.[0]?.[0]?.[0])
    const maxDate = dayjs(source?.[0]?.[source[0].length-1]?.[0])
    const moreThanOneCam = CAMERAS.length > 1
    const title = TITLE ? `${TITLE}_` : ""
    const camera = CAMERAS[selectedCamera].name
        ? `${CAMERAS[selectedCamera].name}_` 
        : moreThanOneCam 
            ? `${t("events.camera")} ${CAMERAS[selectedCamera].id + 1}_` 
            : ""
    let startDate = downloadSpan[0].format("YY-MM-DD")
    const endDate = downloadSpan[1].format("YY-MM-DD")

    if (startDate !== endDate) {
        startDate += "_" + endDate
    }
    
    const targetFilename = `${title}${camera}${startDate}` || t("actions.download")

    useEffect(() => {
        setDownloadSpan([timestamp, timestamp])
    }, [timestamp])

    useEffect(()=> {
        if (isDownloadRangeExceeded) {
            setAlertMessage(t("msg.select_one_month"));
            if (isMobile && isPortrait) {
                setError(true)
            }
        } else {
            setAlertMessage(null);
            if (isMobile && isPortrait) {
                setError(false)
            }
        }
    }, [isDownloadRangeExceeded, downloadSpan, isPortrait, setError, t])

    const onChangeSelectedCamera = event => {
        setSelectedCamera(event.target.value)
        setAlertMessage(null);
    }

    const onChangeDownloadSpan = (dateRange) => {
        setAlertMessage(null);
        if (dateRange?.length) {
            setDownloadSpan([dateRange[0], dateRange[1]])
            setPredefinedSpan("")
        }
    }

    const onChangeStartDate = (startDate) => {
        setAlertMessage(null);
        if (startDate) {
            setDownloadSpan([startDate, downloadSpan[1]])
            setPredefinedSpan("")
        }
    }

    const onChangeEndDate = (endDate) => {
        setAlertMessage(null);
        if (endDate) {
            setDownloadSpan([downloadSpan[0], endDate ])
            setPredefinedSpan("")
        }
    }

    const onChangePredefinedSpan = (event, newPredefinedSpan) => {
        if (newPredefinedSpan) {
            switch (newPredefinedSpan) {
                case "day":
                    const yesterday = today.subtract(1, 'day')
                    setDownloadSpan([yesterday, yesterday])
                    break;
                case "week":
                    var lastWeek = today.subtract(1, 'week')
                    setDownloadSpan([lastWeek?.day(1), lastWeek?.day(7)])
                    break;
                case "month":
                    var lastMonth = today.subtract(1, 'month')
                    setDownloadSpan([lastMonth?.date(1), lastMonth?.endOf("month")])
                    break;
                default:
                    break;
            }

            setPredefinedSpan(newPredefinedSpan)
            setKey(!key) // force render
        }
    }

    function download() {
        
        dispatch(setArchiveProgress(0))
        setLoadingDownload(true)

        const filteredData = []
        const list = source?.[0]

        for (let i=0; i<list?.length; i++) {

            const elem = list[i]
            const databaseDateByCamera = elem[1]?.[selectedCamera]?.datum

            if (dayjs(elem?.[0]).isBetween(downloadSpan[0], downloadSpan[1], "day", "[]")
                && dayjs(databaseDateByCamera).isBetween(downloadSpan[0], downloadSpan[1], "day", "[]")) { // compare selected camera image date with span to before pushing into download list
                filteredData.push(databaseDateToFilename(elem[1][selectedCamera].datum))
            }            
        }

        if (filteredData.length) {
            downloadAll(0, filteredData)
        } else {
            setLoadingDownload(false)
            setAlertMessage(t("msg.no_imgs"))
            if (isMobile && isPortrait) {
                setError(true)
            }
        }
    }

    // see: https://github.com/101arrowz/fflate/wiki/Guide:-Modern-(Buildless) 
    // see: https://github.com/101arrowz/fflate/discussions/92
    function downloadAll(index, data, zip, zipReadableStream) {

        const fflToRS = fflateStream =>
            new ReadableStream({
                start(controller) {
                    fflateStream.ondata = (err, data, final) => {
                        if (err) {
                            controller.error(err)
                        } else {
                            controller.enqueue(data)
                            if (final) {
                                controller.close()
                            }
                        }
                    }
                },
                cancel() {
                    fflateStream.terminate()
                }
            })

        const addFileToZip = async(fileData, fileName, zip) => {
            const zippedFileStream = new ZipPassThrough(fileName)
            zip.add(zippedFileStream)
            const fileReader = fileData.stream().getReader()
            while (true) {
                const { done, value } = await fileReader.read()
                if (done) {
                    zippedFileStream.push(new Uint8Array(0), true)
                    break
                }
                zippedFileStream.push(value)
            }
        }

        zip = zip ? zip : new Zip()
        zipReadableStream = zipReadableStream ? zipReadableStream : fflToRS(zip)

        fetch(CAMERAS[selectedCamera]?.download + data[index])
        .then(res => {
            if (res.ok) {
                return res.blob()
            } else {
                if (DEBUG) {
                    console.error(res.status, res.statusText)
                }

                if (index++ < data.length - 1) {
                    downloadAll(index++, data, zip, zipReadableStream)
                    dispatch(setArchiveProgress((index*100)/data.length))
                } else {
                    setLoadingDownload(false)
                    dispatch(setArchiveProgress(0))
                    setAlertMessage(t("msg.no_imgs"))
                    if (isMobile && isPortrait) {
                        setError(true)
                    }
                }
            } 
        })  
        .then(blob => {
            if (blob) {
                addFileToZip(blob, data[index], zip)
                .then(() => {

                    if (index++ < data.length - 1) {

                        downloadAll(index++, data, zip, zipReadableStream)
                        dispatch(setArchiveProgress((index*100)/data.length))

                    } else {

                        zip.end()

                        const zipResponse = new Response(zipReadableStream)

                        zipResponse.blob().then(res => {
                            var resBlob = new Blob([res])
                            saveFile(resBlob, `${targetFilename}.zip`)
                        })
                        
                        setLoadingDownload(false)
                        dispatch(setArchiveProgress(0))
                    } 
                })
                .catch(error => console.error(error))
            }
        })
        .catch(error => console.error(error))
    }

    useEffect(() => {
        if (archiveProgress > 0) {
            setLoadingDownload(true)
        }
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            {alertMessage && (
                <div style={{
                    marginBottom: isMobile && isPortrait ? 0 : 16,
                    width: '100%'
                }}>
                    <Alert variant="filled" severity="warning" sx={{ alignItems: "center" }}>
                        {alertMessage}
                    </Alert>
                </div>
            )}

            {moreThanOneCam && (
                <TextField
                    select
                    label={t("events.camera")}
                    value={selectedCamera}
                    onChange={onChangeSelectedCamera}
                    fullWidth
                    sx={{ pb: isMobile && isPortrait ? 0 : 2 }}
                    disabled={!CAMERAS?.length || isLoadingDownload}
                >
                    {CAMERAS.map((camera) => (
                        <MenuItem key={camera.id} value={camera.id}>
                            {camera.name !== "" ? camera.name : `${t("events.camera")} ${camera.id + 1}`}
                        </MenuItem>
                    ))}
                </TextField>
            )}

            {!isMobile ? (
                <div>
                    <LocalizationProvider
                        dateAdapter={DayjsAdapter}
                        locale={i18next.language}
                    >
                        <div style={{ display: 'flex'}}>
                            <DatePicker
                                label={t("date.start_date")}
                                inputFormat={t("date.format.short")}
                                mask={t("date.mask.short")}
                                renderInput={props => 
                                    <TextField 
                                        {...props} 
                                        sx={{
                                            width: "100%", 
                                            userSelect: "none",
                                            "& .MuiOutlinedInput-root": {
                                                "& > fieldset": { borderColor: "#4caf50" },
                                            }, 
                                            "& .MuiInputAdornment-root": {
                                                margin: '-4px'
                                            }
                                        }}
                                    />    
                                }
                                onError={reason => reason ? setStartDateValid(false) : setStartDateValid(true)}
                                disableFuture={true}
                                value={downloadSpan[0]}
                                onChange={onChangeStartDate}
                                cancelText={t("actions.cancel")}
                                toolbarTitle={t("date.select_date")}
                                minDate={minDate}
                                maxDate={downloadSpan[1]}
                                loading={loading}
                                disabled={selectedCamera == null || isLoadingDownload}
                                renderLoading={() => <CalendarPickerSkeleton />}
                            />
                        
                            <p style={{
                                paddingLeft: 12, 
                                paddingRight: 12, 
                                height: "100%",
                                alignItems: 'center',
                                userSelect: 'none'
                            }}>
                                {t("date.to")}
                            </p>

                            <DatePicker
                                label={t("date.end_date")}
                                inputFormat={t("date.format.short")}
                                mask={t("date.mask.short")}
                                renderInput={props => 
                                    <TextField
                                        {...props} 
                                        sx={{
                                            width: "100%", 
                                            userSelect: "none",
                                            "& .MuiOutlinedInput-root": {
                                                "& > fieldset": { borderColor: "#ed6c02" },
                                            }, 
                                            "& .MuiInputAdornment-root": {
                                                margin: '-4px',
                                            }
                                        }}
                                    />    
                                }
                                onError={reason => reason ? setEndDateValid(false) : setEndDateValid(true)}
                                disableFuture={true}
                                value={downloadSpan[1]}
                                onChange={onChangeEndDate}
                                cancelText={t("actions.cancel")}
                                toolbarTitle={t("date.select_date")}
                                minDate={downloadSpan[0]}
                                maxDate={maxDate}
                                loading={loading}
                                disabled={selectedCamera == null || isLoadingDownload}
                                renderLoading={() => <CalendarPickerSkeleton />}
                            />
                        </div>
                    </LocalizationProvider>
                </div>
            ) : (
                <div>
                    <LocalizationProvider
                        dateAdapter={DayjsAdapter}
                        locale={i18next.language}
                    >

                        <div style={{ display:'flex' }}>
                            <DatePicker
                                label={t("date.start_date")}
                                inputFormat={(t("date.format.full"))}
                                mask={t("date.mask.full")}
                                renderInput={props => <TextField {...props} sx={{ width: "100%", userSelect: "none" }} /> }
                                value={downloadSpan[0]}
                                onChange={onChangeStartDate}
                                cancelText={t("actions.cancel")}
                                toolbarTitle={t("date.select_date")}
                                minDate={minDate}
                                maxDate={downloadSpan[1]}
                                loading={loading}
                                disabled={selectedCamera == null || isLoadingDownload}
                                renderLoading={() => <CalendarPickerSkeleton />}
                                showTodayButton
                                todayText={t("date.today")}
                            />
                            
                            <p style={{
                                paddingLeft: 12, 
                                paddingRight: 12, 
                                height: "100%",
                                alignItems: 'center',
                                userSelect: 'none'
                            }}>
                                {t("date.to")}
                            </p>

                            <DatePicker
                                label={t("date.end_date")}
                                inputFormat={(t("date.format.full"))}
                                mask={t("date.mask.full")}
                                renderInput={props => <TextField {...props} sx={{ width: "100%", userSelect: "none" }} /> }
                                value={downloadSpan[1]}
                                onChange={onChangeEndDate}
                                cancelText={t("actions.cancel")}
                                toolbarTitle={t("date.select_date")}
                                minDate={downloadSpan[0]}
                                maxDate={maxDate}
                                loading={loading}
                                disabled={selectedCamera == null || isLoadingDownload}
                                renderLoading={() => <CalendarPickerSkeleton />}
                                showTodayButton
                                todayText={t("date.today")}
                            />
                        </div>
                    </LocalizationProvider>
                </div>
            )}

            {!isMobile && (
                <Box
                    sx={{
                        "& > div": {
                            minWidth: siderWidth,
                            pt: 2
                        },
                        "& > div > div": {
                            border: "1px solid",
                            borderColor: "#BDBDBD",
                            borderRadius: "4px"
                        },
                        "& > div > div, & > div > div > div, & > div > div > div > div": {
                            width: siderWidth - 2,
                            minHeight: siderWidth + 24
                        },
                        "& .MuiTypography-caption": {
                            width: daySize + 4,
                            margin: 0
                        },
                        "& .PrivatePickersSlideTransition-root": {
                            minHeight: daySize * 7.2,
                            overflow: "hidden"
                        },
                        '& .PrivatePickersSlideTransition-root [role="grid"]': {
                            width: siderWidth
                        },
                        "& .MuiPickersDay-dayWithMargin": {
                            margin: 0
                        },
                        "& .MuiPickersDay-root": {
                            width: daySize,
                            height: daySize
                        }
                    }}
                >
                    <LocalizationProvider 
                        dateAdapter={DayjsAdapter}
                        locale={i18next.language}
                        key={key}
                    >
                        <StaticDateRangePicker
                            calendars={1}
                            displayStaticWrapperAs="desktop"
                            mask={t("date.mask.full")}
                            value={downloadSpan}
                            onChange={onChangeDownloadSpan}
                            renderInput={() => {}}
                            minDate={minDate}
                            maxDate={maxDate}
                            loading={loading}
                            disabled={selectedCamera == null || isLoadingDownload}
                            shouldDisableDate={shouldDisableDate}
                            renderLoading={() => <CalendarPickerSkeleton />}
                        />
                    </LocalizationProvider>
                </Box>
            )}

            <ToggleButtonGroup
                color="primary"
                exclusive
                onChange={onChangePredefinedSpan}
                value={predefinedSpan}
                fullWidth
                orientation={isMobile ? 'horizontal' : 'vertical'}
                sx={{ py: isMobile && isPortrait ? 0 : 2 }}
            >
                <ToggleButton disabled={selectedCamera == null || loading || isLoadingDownload || today.subtract(1, 'day') < minDate} value="day">{t("date.yesterday")}</ToggleButton> 
                <ToggleButton disabled={selectedCamera == null || loading || isLoadingDownload || today.subtract(1, 'week').day(1) < minDate} value="week">{t("date.last_week")}</ToggleButton>
                <ToggleButton disabled={selectedCamera == null || loading || isLoadingDownload || today.subtract(1, 'month').date(1) < minDate} value="month">{t("date.last_month")}</ToggleButton>
            </ToggleButtonGroup>
           
            <LoadingButton 
                variant="contained" 
                size="large"
                startIcon={<DownloadIcon />}
                onClick={download}
                disabled={isDisabledDownload}
                loading={isLoadingDownload}
                loadingIndicator={<Progress value={archiveProgress} />}
                fullWidth
            >
                {t("actions.download")}
            </LoadingButton>
        </>
    )
}