import dayjs from 'dayjs';
import React, { Fragment, useState, useEffect } from 'react';
import { Grid, Divider, Typography, Stack, Popover, IconButton, Badge, Tooltip } from '@mui/material';
import { Air, WbTwilight, WarningAmber, OpacityRounded } from '@mui/icons-material';
import { useCurrentWeatherQuery } from '../../api/graphqlApi';
import { useTranslation } from 'react-i18next';
import { normalizeWeatherCode } from '../Utilities';

const weatherIconUrls = {
    1: "https://static.meteomatics.com/widgeticons/wsymbol_0001_sunny.png",
    101: "https://static.meteomatics.com/widgeticons/wsymbol_0008_clear_sky_night.png",
    2: "https://static.meteomatics.com/widgeticons/wsymbol_0002_sunny_intervals.png",
    102: "https://static.meteomatics.com/widgeticons/wsymbol_0041_partly_cloudy_night.png",
    3: "https://static.meteomatics.com/widgeticons/wsymbol_0043_mostly_cloudy.png",
    103: "https://static.meteomatics.com/widgeticons/wsymbol_0044_mostly_cloudy_night.png",
    4: "https://static.meteomatics.com/widgeticons/wsymbol_0003_white_cloud.png",
    104: "https://static.meteomatics.com/widgeticons/wsymbol_0042_cloudy_night.png",
    5: "https://static.meteomatics.com/widgeticons/wsymbol_0018_cloudy_with_heavy_rain.png",
    105: "https://static.meteomatics.com/widgeticons/wsymbol_0034_cloudy_with_heavy_rain_night.png",
    6: "https://static.meteomatics.com/widgeticons/wsymbol_0021_cloudy_with_sleet.png",
    106: "https://static.meteomatics.com/widgeticons/wsymbol_0037_cloudy_with_sleet_night.png",
    7: "https://static.meteomatics.com/widgeticons/wsymbol_0020_cloudy_with_heavy_snow.png",
    107: "https://static.meteomatics.com/widgeticons/wsymbol_0036_cloudy_with_heavy_snow_night.png",
    8: "https://static.meteomatics.com/widgeticons/wsymbol_0009_light_rain_showers.png",
    108: "https://static.meteomatics.com/widgeticons/wsymbol_0025_light_rain_showers_night.png",
    9: "https://static.meteomatics.com/widgeticons/wsymbol_0011_light_snow_showers.png",
    109: "https://static.meteomatics.com/widgeticons/wsymbol_0027_light_snow_showers_night.png",
    10: "https://static.meteomatics.com/widgeticons/wsymbol_0013_sleet_showers.png",
    110: "https://static.meteomatics.com/widgeticons/wsymbol_0029_sleet_showers_night.png",
    11: "https://static.meteomatics.com/widgeticons/wsymbol_0006_mist.png",
    111: "https://static.meteomatics.com/widgeticons/wsymbol_0063_mist_night.png",
    12: "https://static.meteomatics.com/widgeticons/wsymbol_0007_fog.png",
    112: "https://static.meteomatics.com/widgeticons/wsymbol_0064_fog_night.png",
    13: "https://static.meteomatics.com/widgeticons/wsymbol_0050_freezing_rain.png",
    113: "https://static.meteomatics.com/widgeticons/wsymbol_0068_freezing_rain_night.png",
    14: "https://static.meteomatics.com/widgeticons/wsymbol_0024_thunderstorms.png",
    114: "https://static.meteomatics.com/widgeticons/wsymbol_0040_thunderstorms_night.png",
    15: "https://static.meteomatics.com/widgeticons/wsymbol_0048_drizzle.png",
    115: "https://static.meteomatics.com/widgeticons/wsymbol_0066_drizzle_night.png",
    16: "https://static.meteomatics.com/widgeticons/wsymbol_0056_dust_sand.png",
    116: "https://static.meteomatics.com/widgeticons/wsymbol_0074_dust_sand_night.png"
}

export default function Weather() {

    const { t } = useTranslation()
    const { data: weatherData, isLoading, error } = useCurrentWeatherQuery(null, { pollingInterval: window.conf.LAYOUT.REFRESH_MIN * 60000 })

    const [anchorEl, setAnchorEl] = useState(null)
    const [badgeVisible, setBadgeVisible] = useState(false)
    
    const icon = weatherIconUrls[weatherData?.weatherCode]
    const alt = weatherData?.weatherCode ? t(`weather.conditions.${normalizeWeatherCode(weatherData.weatherCode)}`) : ""
    const outdatedData = dayjs(weatherData?.dateUpdate).day() !== dayjs().day() 
    const alertsAvailable = weatherData?.weatherLatestAlerts.length

    useEffect(() => {
        if (alertsAvailable && !badgeVisible) {
            setBadgeVisible(true)
        } else {
            setBadgeVisible(false)
        }
    }, [weatherData?.weatherLatestAlerts]) // eslint-disable-line react-hooks/exhaustive-deps

    const handleOpen = (event) => {
        if (anchorEl !== event?.currentTarget) {
            setAnchorEl(event.currentTarget)
        }
    }

    const handleClose = () => {
        if (anchorEl) {
            setAnchorEl(null)
        }
    }

    if (isLoading || !weatherData || outdatedData || error) {
        return null
    } else {
        return (
            <Fragment>
                <Tooltip title={t("weather.weather")} arrow>
                    <IconButton
                        color="inherit"
                        sx={{ 
                            p: 0,
                            pr: { xs: '12px', md: "4px" },
                            pb: "1px"
                        }}
                        onClick={handleOpen}
                    >
                        <Badge color='error' variant='dot' invisible={!badgeVisible}>
                            <img 
                                src={icon} 
                                alt={alt}
                                height={32}
                            />
                        </Badge>
                    </IconButton>
                </Tooltip>

                <Popover
                    id={Boolean(anchorEl) ? 'weather-popover' : undefined}
                    open={Boolean(anchorEl)}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center'
                    }}
                >
                    <Details weatherData={weatherData} />
                </Popover>
            </Fragment>
        )
    }
}

function Details({ weatherData }) {

    const { t } = useTranslation()
    const fontSize = "0.9rem"
    const sunrise = dayjs(weatherData?.sunrise).format("H:mm")
    const sunset = dayjs(weatherData?.sunset).format("H:mm")
    const tomorrow = dayjs().add(1, 'day').day()
    const dayAfterTomorrow = dayjs().add(2, 'day').day()
    const dayAfterDayAfterTomorrow = dayjs().add(3, 'day').day()
    const lastModified = dayjs(weatherData?.dateUpdate).format(t("date.format.short_date_time"))
    const weatherDescription = t(`weather.conditions.${normalizeWeatherCode(weatherData?.weatherCode)}`)

    // const today = dayjs().day()
    // const [error, setError] = useState(t(`msg.weather_outdated`))

    // useEffect(() => {
    //     if (today !== dayjs().day()) {
    //         setError(t(`msg.weather_outdated`))
    //     }
    // }, [today])
    
    // function getClassNameByTemp(temp) {
	// 	if (temp >= 35) {
	// 		return "Weather-boiling"
	// 	}
	// 	if (temp < 35 && temp >= 30) {
    //         return "Weather-hot"
	// 	}
	// 	if (temp < 30 && temp >= 20) {
    //         return "Weather-warm"
	// 	}
	// 	if (temp < 20 && temp >= 10) {
    //         return "Weather-perfect"
	// 	}
	// 	if (temp < 10 && temp >= 0) {
    //         return "Weather-cool"
	// 	}
	// 	if (temp < 0) {
    //         return "Weather-freezing"
	// 	}
	// }
    
    return (
        <Grid
            container
            rowSpacing={1.6}
            sx={{ 
                textAlign: "center", 
                p: 2, 
                width: "280px",
                userSelect: "none"
            }}
            // className={getClassNameByTemp(temp)}
        >

            {/* {error && 
            <Fragment>
                <Grid item xs={12}>
                    <Alert variant="filled" severity="warning">
                        {error}
                    </Alert>

                </Grid>
                <Grid item xs={12}>
                    <Divider />
                </Grid>
            </Fragment>} */}

            <Grid item xs={12}>
                <Stack 
                    spacing={1}
                    direction="row"
                    sx={{ 
                        alignItems: "center",
                        justifyContent: "center",
                        pb: "8px"
                    }}
                >

                    <img 
                        src={weatherIconUrls[weatherData?.weatherCode]} 
                        alt={weatherDescription} 
                        height={56}
                    />

                    <Divider orientation="vertical" variant="middle" flexItem />

                    <Typography style={{ fontSize: "2.1rem" }} sx={{ pl: "8px" }}>
                        {Math.round(weatherData?.temp)}&deg;
                    </Typography>

                </Stack>
                
                <Typography style={{ fontSize: "1.3rem" }}>
                    {weatherDescription}
                </Typography>

            </Grid>

            <Grid item xs={12}>
                <Divider />
            </Grid>

            <Tooltip title={t("weather.humidity")} arrow> 
                <Grid item xs={4}>
                    <OpacityRounded />
                    <Typography style={{ fontSize: fontSize }}>{weatherData?.humidity}%</Typography>
                </Grid>
            </Tooltip>

            <Tooltip title={t("weather.wind")} arrow> 
                <Grid item xs={4}>
                    <Air />
                    <Typography style={{ fontSize: fontSize }}>{weatherData?.windSpeed} km/h</Typography>
                </Grid>
            </Tooltip>

            <Tooltip title={t("weather.sun")} arrow> 
                <Grid item xs={4}>
                    <WbTwilight />
                    <Typography style={{ fontSize: fontSize }}>{sunrise} {sunset}</Typography>
                </Grid>
            </Tooltip>

            <Grid item xs={12}>
                <Divider />
            </Grid>

            <Grid item xs={4}>
                <Typography style={{ fontSize: fontSize }}>{t(`weather.week_days.${tomorrow}`)}</Typography>
                <img 
                    height={36}
                    src={weatherIconUrls[weatherData?.forecastCode1]} 
                    alt={t(`weather.conditions.${normalizeWeatherCode(weatherData?.forecastCode1)}`)} 
                />
                <Typography style={{ fontSize: fontSize }}>{Math.round(weatherData?.forecastMaxTemp1)}&deg; / {Math.round(weatherData?.forecastMinTemp1)}&deg;</Typography>
            </Grid>

            <Grid item xs={4}>
                <Typography style={{ fontSize: fontSize }}>{t(`weather.week_days.${dayAfterTomorrow}`)}</Typography>
                <img 
                    height={36}
                    src={weatherIconUrls[weatherData?.forecastCode2]} 
                    alt={t(`weather.conditions.${normalizeWeatherCode(weatherData?.forecastCode2)}`)} 
                />
                <Typography style={{ fontSize: fontSize }}>{Math.round(weatherData?.forecastMaxTemp2)}&deg; / {Math.round(weatherData?.forecastMinTemp2)}&deg;</Typography>
            </Grid>

            <Grid item xs={4}>
                <Typography style={{ fontSize: fontSize }}>{t(`weather.week_days.${dayAfterDayAfterTomorrow}`)}</Typography>
                <img 
                    height={36}
                    src={weatherIconUrls[weatherData?.forecastCode3]} 
                    alt={t(`weather.conditions.${normalizeWeatherCode(weatherData?.forecastCode3)}`)} 
                />
                <Typography style={{ fontSize: fontSize }}>{Math.round(weatherData?.forecastMaxTemp3)}&deg; / {Math.round(weatherData?.forecastMinTemp3)}&deg;</Typography>
            </Grid>

            {weatherData?.weatherLatestAlerts?.map((alert, index) => (
                <Fragment key={index}>

                    <Grid item xs={12}>
                        <Divider />
                    </Grid>

                    <Grid item xs={12}>
                        <Stack 
                            spacing={2}
                            direction="row"
                            sx={{ 
                                alignItems: "center",
                                justifyContent: "center",
                            }}
                        >
                            <WarningAmber fontSize='small' />
                            <Typography 
                                style={{ 
                                    fontSize: fontSize, 
                                    textAlign: "justify", 
                                    marginRight: "16px" 
                                }}
                            >
                                {t(`weather.alerts.${alert.event.replace(":idx","")}.${alert.description}`)}
                            </Typography>
                        </Stack>
                    </Grid>

                </Fragment>
            ))}
            
            <Grid item xs={12}>
                <Divider />
            </Grid>

            <Grid
                item
                display="flex"
                justifyContent="center"
                alignItems="center"
                xs={12}
                gap={0.5}
            >
                <Typography 
                    style={{ 
                        fontSize: fontSize, 
                        textAlign: "justify",
                        whiteSpace: "nowrap"
                    }}
                >

                    {t(`msg.last_update`)}:
                </Typography>
                <Typography 
                    style={{ 
                        fontSize: fontSize, 
                        textAlign: "justify",
                        whiteSpace: "nowrap"
                    }}
                >
                    {lastModified}
                </Typography>
            </Grid>
        </Grid>
    )
}