import { styled, Accordion as MuiAccordion, AccordionDetails as MuiAccordionDetails, AccordionSummary as MuiAccordionSummary } from "@mui/material";
import { ArrowForwardIosSharp } from "@mui/icons-material";
import { isMobile } from 'react-device-detect';

export const Accordion = styled(({ expanded, isPortrait = false, ...props }) => (
    <MuiAccordion
        disableGutters
        square
        elevation={0}
        expanded={expanded} 
        slotprops={{ transition: { unmountOnExit: true } }} // improve performance (see https://mui.com/material-ui/react-accordion/#performance)
        {...props}
    />
))(({ theme, expanded, isPortrait }) => ({
    borderTop: `1px solid ${theme.palette.divider}`,
    borderLeft: isMobile && !isPortrait && `1px solid ${theme.palette.divider}`,
    borderBottom: `1px solid ${theme.palette.divider}`,
    '&:not(:last-child)': {
        borderBottom: 0
    },
    '&:last-child': {
        borderBottom: expanded && !isMobile && 0
    },
    '&::before': {
        display: 'none'
    }
}));
  
export const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
        expandIcon={<ArrowForwardIosSharp sx={{ fontSize: '0.9rem' }} />}
        {...props}
    />
))(({ theme }) => ({
    backgroundColor: '#F7F7F7',
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(90deg)'
    },
    '& .MuiAccordionSummary-content': {
        ...theme.typography.h6,
        marginLeft: theme.spacing(1),
        textTransform: 'uppercase',
        fontSize: { xs: '1rem', sm: '1.25rem' },
        fontWeight: undefined
    }
}));
  
export const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: `1px solid ${theme.palette.divider}`
}));