import React, { useEffect, useRef, useState } from 'react';
import { Global } from '@emotion/react';
import { Container, SwipeableDrawer, Box, Toolbar, Drawer } from '@mui/material';
import { isMobile, useMobileOrientation } from 'react-device-detect';
import { useDispatch, useSelector } from 'react-redux';
import { getData, setIndex, setInitialFooterHeight, setSource, setTimestamp } from '../../api/dataSlice';
import { useGetDataByCameraQuery } from '../../api/graphqlApi';
import { useWindowDimensions } from '../Utilities';
import Form from './Form';

export default function Controls({ initialSiderWidth, onOpenSideDrawer, setPaddingFooter, drawerBleeding }) {

    const { isPortrait } = useMobileOrientation();
    const { source, index } = useSelector(getData);
    const { data, isLoading: loading } = useGetDataByCameraQuery(null, { pollingInterval: 600000 });
    const { DEBUG } = window.conf;
    const { appbarHeight } = useWindowDimensions();

    const fullHeightWithoutAppbar = `calc(100vh - ${appbarHeight}px)`
    const dispatch = useDispatch();
    const footerWrapperRef = useRef(null);

    const [footerOpen, setFooterOpen] = useState(false);
    const [firstRun, setFirstRun] = useState(true);
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const [footerHeight, setFooterHeight] = useState(fullHeightWithoutAppbar);

    const toggleDrawer = (isOpen) => {
        setIsDrawerOpen(isOpen);
        onOpenSideDrawer(isOpen);
        if (isOpen) document.body.style.overflow = 'auto';
    };

    const toggleFooter = (isOpen) => {
        if (isOpen) {
            const vh80 = window.innerHeight * 0.8;
            // check content less 80vh and set footer height
            const contentHeight = footerWrapperRef.current?.offsetHeight;
            if (contentHeight < vh80) {
                setFooterHeight(`${contentHeight}px`);
            } else {
                setFooterHeight(fullHeightWithoutAppbar);
            }
        }
        setFooterOpen(isOpen);
    };

    const updateTimestamp = (timestamp) => {
        dispatch(setTimestamp(timestamp));
    };

    useEffect(() => {
        if (index) {
            updateTimestamp(source?.[0]?.[index]?.[0]);
        }
    }, [index]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (data) {
            if (firstRun) {
                var list = data[0];
                var lastIndex = list.length - 1;
                var lastElement = list[lastIndex];

                updateTimestamp(lastElement?.[0]); // set time in TimeSelector for display clock by date of last image (ONLY ONCE!)
                dispatch(setIndex(lastIndex)); // set index in redux store for Controls
                setFirstRun(false);
            }

            dispatch(setSource(data)); // set source in redux store for Content

            DEBUG && console.log('source', data);
        }
    }, [data, dispatch]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (isMobile) {
            dispatch(setInitialFooterHeight(16));
        } else {
            dispatch(setInitialFooterHeight(0));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isMobile]);

    // check footerWrapperRef resize
    useEffect(() => {
        const resizeObserver = new ResizeObserver((entries) => {
            for (let entry of entries) {
                const { height } = entry.contentRect;
                const outerHeight = height + drawerBleeding + 8;
                if (outerHeight < window.innerHeight * 0.8) {
                    setFooterHeight(`${outerHeight}px`);
                } else {
                    setFooterHeight(fullHeightWithoutAppbar);
                }
            }
        });
        if (footerWrapperRef.current) resizeObserver.observe(footerWrapperRef.current);
        return () => resizeObserver.disconnect();
    }, [footerOpen]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        setPaddingFooter(drawerBleeding)
    }, [drawerBleeding, setPaddingFooter])

    useEffect(() => {
        return () => {
            onOpenSideDrawer(false)
            setIsDrawerOpen(false);
        }
    }, []) // eslint-disable-line react-hooks/exhaustive-deps  

    // mobile footer portrait
    if (isMobile && isPortrait) {
        return (
            <Container sx={{ backgroundColor: 'black', height: '100%' }}>

                <Global
                    styles={{
                        '.MuiDrawer-root > .MuiPaper-root': {
                            height: `calc(${footerHeight} - ${drawerBleeding}px - 6px)`, // 6px because otherwise there is white space below second accordion
                            overflow: 'visible'
                        },
                    }}
                />

                <SwipeableDrawer
                    anchor="bottom"
                    open={footerOpen}
                    onClose={() => toggleFooter(false)}
                    onOpen={() => toggleFooter(true)}
                    swipeAreaWidth={drawerBleeding + 9} // increases the area from which swiping is possible
                    disableSwipeToOpen={false}
                    ModalProps={{ keepMounted: true }}
                >
                    <Box
                        sx={{
                            position: 'absolute',
                            top: -drawerBleeding,
                            borderTopLeftRadius: 8,
                            borderTopRightRadius: 8,
                            visibility: 'visible',
                            right: 0,
                            left: 0,
                            backgroundColor: 'white'
                        }}
                    >

                        {/* puller component to pull out the drawer */}

                        <Box
                            sx={{
                                width: 30,
                                height: 6,
                                backgroundColor: footerOpen ? '#e0e0e0' : '#003366',
                                borderRadius: '3px',
                                position: 'absolute',
                                top: 8,
                                left: 'calc(50% - 15px)',
                            }}
                        />

                        {/* content of the drawer below puller component */}

                        <Box
                            sx={{
                                maxHeight: `calc(${fullHeightWithoutAppbar} - ${drawerBleeding}px - 6px)`, // 6px because otherwise there is white space below second accordion
                                overflowY: 'auto',
                                width: 'auto',
                                overflowX: 'hidden',
                                mt: 3
                            }}
                            ref={footerWrapperRef}
                        >
                            <Form loading={loading} />
                        </Box>

                    </Box>
                </SwipeableDrawer>
            </Container>
        )

    // mobile sider landscape
    } else if (isMobile && !isPortrait) {
        return (
            <>
                <Global
                    styles={{
                        '.MuiDrawer-root > .MuiPaper-root': {
                            height: `100%`,
                            overflow: 'visible',
                        },
                    }}
                />

                <SwipeableDrawer
                    anchor="right"
                    open={isDrawerOpen}
                    onClose={() => toggleDrawer(false)}
                    onOpen={() => toggleDrawer(true)}
                    swipeAreaWidth={drawerBleeding + 9} // increases the area from which swiping is possible
                    disableSwipeToOpen={false}
                    hideBackdrop={true} // prevent the background from graying out outside the sider
                    ModalProps={{ keepMounted: true }}
                    sx={{
                        height: '100%',
                        flexShrink: 0,
                        [`& .MuiDrawer-paper`]: {
                            width: initialSiderWidth - drawerBleeding,
                            boxSizing: 'border-box',
                        },
                    }}
                >
                    <Box
                        sx={{
                            backgroundColor: "white",
                            position: 'absolute',
                            height: '100%',
                            top: 0,
                            right: 0,
                            left: -drawerBleeding,
                            visibility: 'visible',
                            overflow: 'auto',
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            overflowX: 'hidden',
                        }}
                    >

                        {/* puller component to pull out the drawer */}

                        <Box
                            sx={{
                                position: 'absolute',
                                backgroundColor: isDrawerOpen ? '#e0e0e0' : '#003366',
                                height: 30,
                                width: 6,
                                top: `calc(50% + 8px)`,
                                left: 8,
                                borderRadius: 3
                            }}
                        />
                        
                        {/* content of the drawer below puller component */}

                        <Box
                            sx={{
                                overflow: 'auto',
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                mt: `${appbarHeight}px`,
                                ml: `${drawerBleeding}px`,
                                height: '100%',
                                maxHeight: fullHeightWithoutAppbar,
                                width: "100%"
                            }}
                        >
                            <Form loading={loading} />
                        </Box>

                    </Box>
                </SwipeableDrawer>
            </>
        )

    // desktop sider
    } else {
        return (
            <Drawer
                variant="permanent"
                anchor="right"
                sx={{
                    width: initialSiderWidth,
                    flexShrink: 0,
                    [`& .MuiDrawer-paper`]: {
                        width: initialSiderWidth,
                        boxSizing: 'border-box',
                    },
                }}
            >
                <Toolbar /> {/* provides top distance without additional functionality */}
                <Box
                    sx={{
                        overflow: 'auto',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        overflowX: 'hidden',
                        height: '100%',
                        maxHeight: fullHeightWithoutAppbar
                    }}
                >
                    <Form loading={loading} />
                </Box>
            </Drawer>
        )
    }
}
