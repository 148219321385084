
import '../styles.css';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import 'dayjs/locale/de'
import 'dayjs/locale/nl'
import { StrictMode, Fragment } from 'react';
import { Provider as ReduxProvider } from 'react-redux';
import { store } from '../api/store';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { BrowserRouter as RouterProvider, Routes, Route } from "react-router-dom";
import { ApolloProviderWrapper as SubscriptionProvider } from '../api/graphqlSubscription';
import { KeycloakProvider, useKeycloak } from './Keycloak';
import { initReactI18next } from "react-i18next";
import Header from './header/Header';
import Content from './Content';
import i18n from "i18next";
import en from '../locales/en.json';
import de from '../locales/de.json';
import nl from '../locales/nl.json';
import LanguageDetector from 'i18next-browser-languagedetector'
import Status from './Status';

const { DEBUG, LAYOUT: { DEFAULT_LANG }, ROUTES: { HOME, LIVE, TIMELAPSE, ARCHIVE } } = window.conf

i18n
.use(LanguageDetector) // detect user language
.use(initReactI18next) // pass the i18n instance to react-i18next.
.init({
    debug: DEBUG,
    fallbackLng: 'de', // use de if detected language is not available
    interpolation: { escapeValue: false }, // not needed for react as it escapes by default
    resources: {
        en: { translation: en },
        de: { translation: de },
        nl: { translation: nl }
    },
    lng: window.localStorage.getItem('netco_lang') || DEFAULT_LANG // if no value is stored for language preference in browser set flag to default 
})

export default function Wrapper() {

    // // required to use HashRouter and Keycloak together
    // const currentUrl = window.location.href
    // const ampersandIndex = currentUrl.indexOf('&')
    // const isRedirectUrl = ampersandIndex > -1
    // if (isRedirectUrl) {
    //     window.location.href = currentUrl.substring(0, ampersandIndex)
    // }

    const theme = createTheme({
        palette: {
            primary: { main: "#003366" },
            secondary: { main: "#FFFFFF" }
        }
    })

    const ProtectedRoute = ({ component }) => {
        
        const { hasRole, doLogin, isLoggedIn, isUsersPortal } = useKeycloak()
        
        const isProtected = component.protected
        const canAccessComplete = hasRole(component.role)
        const canAccessPartly = isUsersPortal()

        DEBUG && console.log(`Route: ${component.path} isProtected: ${isProtected} canAccessComplete: ${canAccessComplete} canAccessPartly: ${canAccessPartly} isLoggedIn: ${isLoggedIn()}`)
    
        if (isProtected && !canAccessComplete) {
            if (!isLoggedIn()) {
                doLogin()
                return null
            }
            if (canAccessPartly) {
                return (
                    <Fragment>
                        <Header />
                        <Status code={403} controls={[ "header", "back" ]} />
                    </Fragment>
                )
            } else {
                return (
                    <Status code={403} controls={[ "logout" ]} />
                )
            }
        }
    
        return (
            <Fragment>
                <Header />
                <Content component={component.id} />
            </Fragment>
        )
    }

    return (
        <StrictMode>
            <ReduxProvider store={store}>
                <KeycloakProvider>
                    <SubscriptionProvider>
                        <ThemeProvider theme={theme}>
                            <RouterProvider>
                                <Routes>
                                    <Route path={HOME.path} element={<ProtectedRoute component={HOME} />} />
                                    <Route path={LIVE.path} element={<ProtectedRoute component={LIVE} />} />
                                    <Route path={TIMELAPSE.path} element={<ProtectedRoute component={TIMELAPSE} />} />
                                    <Route path={ARCHIVE.path} element={<ProtectedRoute component={ARCHIVE} />} />
                                    <Route path="*" element={<Status code={404} controls={[ "back" ]}/>} />
                                </Routes>
                            </RouterProvider>
                        </ThemeProvider>
                    </SubscriptionProvider>
                </KeycloakProvider>
            </ReduxProvider>
        </StrictMode>
    )
} 